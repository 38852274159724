import React, { memo, useState, useMemo, useCallback } from "react";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import { useTranslation } from "next-i18next";
import { Form, InputGroup, Button } from "react-bootstrap";

import { isPasswordFieldName, getFormFieldLabel } from "@aft/client-services/forms";

/**
 * Wrapper over Bootstrap Form components,
 * to be used as form input inside Formik's 'Field' component.
 *
 * @param props - Form input props.
 */
const FormInput = memo(
  ({
    field: { value, name, onChange: defaultOnChange, ...fields },
    form: { touched, errors },
    type: defaultType,
    required,
    label,
    asHtml = "input",
    onChange,
    ...props
  }) => {
    const { t } = useTranslation("common");
    /**
     * Indicates whether text of the input should be visible or not.
     * Result will be visible only if current input is password input.
     */
    const [showPassword, setShowPassword] = useState(false);

    /**
     * Handler function for toggle password visibility button.
     */
    const handleTogglePasswordVisibility = useCallback(() => {
      setShowPassword(!showPassword);
    }, [showPassword]);

    /**
     * Input type.
     */
    const type = useMemo(() => {
      if (isPasswordFieldName(name)) {
        return showPassword ? "text" : "password";
      }

      return defaultType || "text";
    }, [showPassword, name, defaultType]);

    return useMemo(
      () => (
        <Form.Group controlId={name}>
          <Form.Label>{getFormFieldLabel(t, label || name, required)}</Form.Label>
          <InputGroup>
            <Form.Control
              name={name}
              value={value}
              type={type}
              label={getFormFieldLabel(t, label || name, required)}
              isInvalid={touched[name] !== undefined && errors[name] !== undefined}
              as={asHtml}
              {...(asHtml === "select"
                ? {
                    custom: true,
                  }
                : {})}
              {...props}
              {...fields}
              onChange={onChange || defaultOnChange}
            />
            {isPasswordFieldName(name) && (
              <InputGroup.Append>
                <Button onClick={handleTogglePasswordVisibility}>
                  <FontAwesomeIcon fixedWidth icon={showPassword ? faEye : faEyeSlash} />
                </Button>
              </InputGroup.Append>
            )}
            <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      ),
      [
        t,
        errors,
        fields,
        touched,
        props,
        type,
        value,
        name,
        label,
        asHtml,
        required,
        showPassword,
        onChange,
        defaultOnChange,
        handleTogglePasswordVisibility,
      ],
    );
  },
);

/**
 * Custom wrapper over a Formik's field component, to be properly displayed using bootstrap styling.
 * Besides that, handles field text visibility.
 */
export const FormInputField = memo(({ as, ...props }) => (
  <Field component={FormInput} asHtml={as} {...props} />
));
