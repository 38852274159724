import React, { memo, useMemo, useEffect } from "react";

import { Form as FormikForm, useFormikContext } from "formik";

/**
 * Custom wrapper over Formik's Form component.
 * Enables scroll-to-first-error feature upon submit attempt.
 */
export const Form = memo(({ children }) => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const errorsKeys = Object.keys(errors);

    if (errorsKeys.length > 0 && isSubmitting && !isValidating) {
      const errorElement =
        document.querySelector(`*[data-scroll-on-error="${errorsKeys[0]}"]`) ||
        document.querySelector(`label[for="${errorsKeys[0]}"]`) ||
        document.getElementById(errorsKeys[0]);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
      }
    }
  }, [isSubmitting, isValidating, errors]);

  return useMemo(() => <FormikForm>{children}</FormikForm>, [children]);
});
