import React, { memo, useMemo } from "react";

import { Field } from "formik";
import { Trans } from "next-i18next";
import { Form, InputGroup } from "react-bootstrap";

import { NextLink } from "@aft/client-components/core/NextLink";
import { clientRoutesManager } from "@aft/client-routes";
import { FormFieldNames } from "@aft/client-services/forms";

const FormInput = memo(
  ({
    field: { name, value, checked, ...fields },
    form: { touched, errors },
    type,
    label: defaultLabel,
    ...props
  }) => {
    /**
     * Input label.
     * Default(passed) label is prioritized to be displayed,
     * otherwise showing the default one for a particular input name.
     */
    const label = useMemo(() => {
      if (defaultLabel) {
        return defaultLabel;
      }

      switch (name) {
        case FormFieldNames.TosConfirmation:
          return (
            <Trans i18nKey={`Form.FieldName.${FormFieldNames.TosConfirmation}`}>
              I have read and agreed to the{" "}
              <NextLink
                href={
                  clientRoutesManager.getRoute(
                    clientRoutesManager.Segments.Terms,
                    clientRoutesManager.Segments.TermsOfService,
                  ).pathname
                }
                className="font-weight-medium"
              >
                terms of use and privacy policy
              </NextLink>
            </Trans>
          );
        default:
          return undefined;
      }
    }, [name, defaultLabel]);

    return useMemo(
      () => (
        <Form.Group controlId={name}>
          <InputGroup>
            <Form.Check
              {...props}
              {...fields}
              name={name}
              label={label}
              value={value}
              checked={checked}
              type={type || "checkbox"}
              isInvalid={touched[name] !== undefined && errors[name] !== undefined}
              feedback={type !== "radio" ? errors[name] : undefined}
            />
          </InputGroup>
        </Form.Group>
      ),
      [errors, fields, touched, props, label, value, checked, type, name],
    );
  },
);

/**
 * Custom wrapper over a Formik's field component, to be properly displayed using bootstrap styling.
 */
export const FormCheckboxField = memo(({ ...props }) => <Field component={FormInput} {...props} />);
