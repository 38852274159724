import React, { memo, useCallback, useMemo } from "react";

import clsx from "clsx";
import { Field } from "formik";
import { useTranslation } from "next-i18next";
import { Form, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";

import { getFormFieldLabel } from "@aft/client-services/forms";

/**
 * Wrapper over Bootstrap Form components,
 * to be used as form input inside Formik's 'Field' component.
 *
 * @param props - Form phone input props.
 */
const FormPhoneInput = memo(
  ({
    field: { value, name },
    form: { touched, errors, handleBlur, setFieldValue },
    required,
    label,
    onChange,
  }) => {
    const { t } = useTranslation("common");

    const handleChange = useCallback(
      (phoneNumberValue) => {
        setFieldValue(name, phoneNumberValue);

        if (onChange) {
          onChange(phoneNumberValue);
        }
      },
      [name, onChange, setFieldValue],
    );

    return useMemo(
      () => (
        <Form.Group controlId={name}>
          <Form.Label>{getFormFieldLabel(t, label || name, required)}</Form.Label>
          <InputGroup>
            <PhoneInput
              name={name}
              value={value}
              label={getFormFieldLabel(t, label || name, required)}
              className={clsx(
                touched[name] !== undefined && errors[name] !== undefined && "is-invalid",
              )}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      ),
      [t, errors, touched, value, name, label, required, handleChange, handleBlur],
    );
  },
);

/**
 * Custom wrapper over a Formik's field component, to be properly displayed using bootstrap styling.
 * Besides that, handles field text visibility.
 */
export const FormPhoneInputField = memo((props) => <Field component={FormPhoneInput} {...props} />);
